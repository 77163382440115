@import './app/common/utils.scss';
@import 'packages/lefty-assets/src/lib/css/nestor-theme';
@import '../../../node_modules/flag-icons/css/flag-icons.min.css';

:root {
  @include nestor-theme;
}

body {
  background-color: white;
}
